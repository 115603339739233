import React from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const Owp = () => {
  const lang = "en";

  return (
    <Layout
      seoTitle="General terms and conditions of carriage"
      lang={lang}
      translationPL="/owp/"
      translationDE="/de/allgemeine-transportbedingungen/"
    >
      <SmallBanner
        title="General terms and conditions of carriage"
        lang={lang}
      />

      <section className="default-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <p>
                These General Terms and Conditions of Carriage, hereinafter
                referred to as the GTCC, are applicable to contracts of carriage
                of goods between Palettenwerk Kozik Sp. z o.o. or Palko Sp. z
                o.o.—hereinafter referred to as the Ordering Party—and the
                Carrier on the basis of transport orders submitted to the
                Carrier by the Ordering Party.
              </p>
              <p>1. General provisions</p>
              <ul>
                <li>
                  The GTCC constitutes an integral part of each transport order
                  issued by the Ordering Party. Derogation from the application
                  of this GTCC shall be made in writing; otherwise it is null
                  and void.
                </li>
                <li>
                  Acceptance of the transport order for execution should be
                  confirmed by the Carrier by email to the following address:{" "}
                  <a href="mailto:logistyka@palettenwerk.pl">
                    logistyka@palettenwerk.pl
                  </a>{" "}
                  .Failure to send the refusal of a transport order to the
                  aforementioned address within 30 minutes of its receipt shall
                  be considered acceptance of the transport order by the
                  Carrier.
                </li>
                <li>
                  Acceptance of a transport order by the Carrier shall be deemed
                  the signing of a contract of carriage on the terms specified
                  in the transport order and in this GTCC.
                </li>
              </ul>
              <p>2. Representations and warranties of the carrier</p>
              <ul>
                <li>
                  By accepting the transport order, the Carrier declares that a)
                  it meets all the conditions and formal requirements for
                  providing national and/or foreign transport services and
                  carries out its transport activity as a professional; b) it
                  has valid, up-to-date liability insurance for any damage
                  caused to the transported goods in an amount corresponding to
                  at least 200% of the value of the goods accepted for
                  transport; c) the vehicles that will be loaded and used to
                  execute transport orders meet all the necessary technical
                  conditions and will be equipped with all the necessary devices
                  in accordance with applicable laws; the vehicles in question
                  will meet the required standards, be in good working order,
                  and be suitable in terms of load capacity and the requirements
                  for height and length specified in the transport order; they
                  will be clean and free of foreign odours and the loading space
                  will be free of other goods and adequately protected against
                  the possibility of damage to the goods and exposure to adverse
                  environmental impact during transport. d) a Mega+ vehicle
                  refers to a vehicle at least 298 cm high and 13.6 m long; a
                  Mega vehicle is a vehicle at least 294 cm high and 13.6 m
                  long; a Standard vehicle denotes a vehicle at least 273 cm
                  high and 13.6 m long; a Truck and trailer refers to a vehicle
                  with a semi-trailer or trailer that is at least 298 cm high
                  and 7.7 m long.
                </li>
                <li>
                  The Carrier is obliged to provide immediately, on each request
                  of the Ordering Party, a policy confirming the existence of a
                  liability insurance contract, as well as certificates,
                  licences, and other documents related to the carriage. The
                  Ordering Party has the right to make copies of the documents
                  provided
                </li>
              </ul>
              <p>3. Carriage rules</p>
              <ul>
                <li>
                  The Carrier is obliged to provide the vehicle to be loaded on
                  time and in the place specified in the transport order.
                  Providing a vehicle whose parameters are inconsistent with the
                  order and/or the GTCC shall be considered a failure to provide
                  the vehicle.
                </li>
                <li>
                  The Carrier is obliged to provide the drivers with tidy and
                  appropriate clothing. When on the Contractor’s premises, the
                  Carrier/driver is obliged to wear high-visibility vests.
                </li>
                <li>
                  Smoking is prohibited on the premises of the Ordering Party.
                  The Carrier/driver is obliged to observe this prohibition.
                </li>
                <li>
                  When waiting for loading on the Ordering Party’s premises, it
                  is not allowed for the Carrier/driver to: a) leave any items
                  behind, including garbage or b) wash the vehicle on the
                  premises of the Ordering Party.
                </li>
                <li>
                  The Carrier/driver may not transport on the semi-trailer other
                  items and goods than those covered by the transport order.
                </li>
                <li>
                  The Carrier ensures constant communication with the Ordering
                  Party and the driver performing the specific contract of
                  carriage.
                </li>
                <li>
                  The Carrier/driver is fully responsible for the correct
                  loading and placement of the goods in the loading space,
                  including the correct axle load of the vehicle.
                </li>
                <li>
                  It is forbidden to leave a loaded vehicle on the Ordering
                  Party’s premises.
                </li>
                <li>
                  During the loading, the Carrier/driver is responsible for the
                  conformity of the collected goods with the documents.
                </li>
                <li>
                  In case there is no load or there are other problems at the
                  place of loading, the Carrier/driver shall not leave the place
                  of loading without the consent of the Ordering Party.
                </li>
                <li>
                  In case of any problems at the place of unloading, the
                  Carrier/driver shall not leave the place of unloading without
                  the consent of the Ordering Party.
                </li>
                <li>
                  The Carrier may not entrust the execution of the transport
                  order to other subcontractors, nor may the Carrier load or
                  reload the goods onto another vehicle without the consent of
                  the Ordering Party.
                </li>
                <li>
                  The Carrier is obliged to obtain from the consignee a receipt
                  on a document used in a given case, confirmed by the legible
                  signature of an authorised person with a company stamp and
                  personal stamp. Upon the request of the Ordering Party, the
                  Carrier shall be obliged to deliver the aforementioned
                  document within 48 hours of unloading—a digital version sent
                  to the email address of the Ordering Party is acceptable.
                </li>
                <li>
                  The Carrier is obliged to deliver the goods exactly on the
                  date indicated in the transport order. The Carrier accepts the
                  fact that transport orders are executed for consignees who
                  apply strict rules concerning the timeliness of deliveries,
                  including providing vehicles for unloading at a specific hour.
                </li>
                <li>
                  The Carrier is liable for all damages arising in connection
                  with the execution of the transport order, in particular for
                  damage to the goods arising from the moment of their
                  acceptance for transport to the moment of their release, as
                  well as for damages arising in connection with documents that
                  are lost or incorrectly filled in, delays in the delivery of
                  goods, or total or partial loss, damage, or shortage of goods.
                </li>
                <li>
                  Failure to comply with any terms and conditions of the
                  transport order and/or the GTCC, in particular a delay/failure
                  to provide a vehicle for loading or a late delivery of goods
                  shall result in the Contractor charging a contractual penalty
                  of up to 200% of the freight price. The aforementioned penalty
                  shall be payable within 14 days of the date of issue of the
                  debit note by the Ordering Party. The Ordering Party reserves
                  the right to claim damages exceeding the amount of the
                  contractual penalty.
                </li>
              </ul>
              <p>4. Payment for services</p>
              <ul>
                <li>
                  The Carrier shall attach transport documents to the invoice,
                  including documents confirming delivery and specification
                  (delivery note) and the transport order. The set of documents
                  shall be delivered within 7 days of the date of unloading the
                  goods. Sending a set of correctly filled in documents is a
                  condition for payment for the service. The Ordering Party
                  shall pay for the service within 45 days of the date of
                  receiving a set of correctly filled in documents.
                </li>
                <li>
                  The Ordering Party shall be entitled to deduct from the
                  Carrier’s remuneration all contractual penalties resulting
                  from the GTCC.
                </li>
                <li>
                  The Carrier shall not be entitled to additional remuneration
                  for waiting for loading or unloading up to 24 hours in the
                  case of national transport or up to 48 hours in the case of
                  foreign transport, counted from the end of the agreed upon
                  day/hour of loading/unloading, excluding bank holidays. The
                  amount of charges for longer stops must be agreed and
                  confirmed in writing by the Ordering Party under pain of
                  invalidity.
                </li>
              </ul>
              <p>5. Personal data processed by the Ordering Party</p>
              <ul>
                <li>
                  The Ordering Party is the controller of the personal data
                  processed in connection with transport orders, to the extent
                  indicated in Section 5.2 below.
                </li>
                <li>
                  Personal data: of the Carrier, i.e. the contracting party
                  (under Art. 6(1)(b) of the GDPR), as well as its
                  representatives and other persons executing the contract, e.g.
                  employees or co-workers—including drivers authorised to
                  collect and deliver goods (under Art. 6(1)(f) of the GDPR,
                  i.e. the legitimate interest of the data controller), shall be
                  processed by the Ordering Party for the purpose of concluding
                  and performing the contract. Personal data may also be
                  processed for the purpose of claiming, determining, or
                  defending against claims related to the performance of the
                  contract in question (under Art. 6(1)(f) of the GDPR, i.e.
                  legitimate interests pursued by the controller). Personal data
                  may also be used for the purpose of fulfilling obligations
                  resulting from public law regulations (Art. 6(1)(c) of the
                  GDPR).
                </li>
                <li>
                  The data shall not be made available to anyone, unless it is
                  necessary for the performance of a contract entered into by
                  the Ordering Party (e.g. indicating the personal data of
                  drivers collecting or delivering the goods to the recipient)
                  or it results from legal regulations.
                </li>
                <li>
                  The Ordering Party may entrust the data to, for example,
                  providers of IT or accounting services or may transfer the
                  correspondence between authorised employees of the Company.
                </li>
                <li>
                  You have the right to access the data, including the right to
                  obtain a copy of the data, the right to data portability, the
                  right to rectification and erasure, the right to restrict
                  processing, and the right to object (when the processing is
                  based on Art. 6(1)(f) of the GDPR). You have the right to
                  lodge a complaint with a supervisory authority (the President
                  of the Personal Data Protection Office).
                </li>
                <li>
                  Personal data shall be deleted or anonymised no later than
                  after the expiry of the period of limitation of potential
                  claims related to the performance of the contract—in
                  particular those arising from the process of concluding and
                  performing the contract signed by the Ordering Party, public
                  law obligations—or shorter if you make an effective objection.
                </li>
                <li>
                  The provision of data is voluntary but necessary to achieve
                  the aforementioned objectives.
                </li>
                <li>
                  In all matters concerning data protection, please contact us
                  by writing to the Ordering Party’s address or to the following
                  email address: rodo@palettenwerk.pl. For more information
                  about the processing of your data, please refer to the Privacy
                  Policy available in the Contact tab at pallettenwerk.pl.
                </li>
                <li>
                  Considering that the Ordering Party’s personal data can be
                  obtained both directly—in the case of the Carrier’s data—and
                  indirectly—in the case of the data of their employees or
                  co-workers (processed for the purposes mentioned above)—the
                  Carrier is obliged to immediately make available a copy of
                  this information clause (Sections 1–8 above) to all persons
                  whose data is given to the Ordering Party in the process of
                  executing transport orders, and is also obliged to inform
                  these persons about the scope of personal data made available.
                </li>
              </ul>
              <p>6. Final resolutions</p>
              <ul>
                <li>
                  All information included in transport orders as well as
                  information obtained by the Carrier/driver during the
                  execution of the contract is confidential and constitutes a
                  trade secret of the Ordering Party’s enterprise. This
                  information cannot be used and disseminated without the
                  consent of the Ordering Party. In case of violation by the
                  Carrier/driver of the trade secret described above, the
                  Carrier shall pay the Ordering Party a contractual penalty in
                  the amount of 60,000 PLN. The aforementioned penalty shall be
                  payable within 14 days of the date of issue of the debit note
                  by the Ordering Party. If the value of the damages exceeds the
                  amount of the contractual penalty, the Ordering Party will be
                  entitled to claim damages on general principles.
                </li>
                <li>
                  In matters not regulated by the GTCC and the transport order,
                  the provisions of the Civil Code and the Transport Law and/or
                  CMR Convention shall apply.
                </li>
              </ul>
              <p>7. Disputes</p>
              <p>
                Any disputes shall be settled in accordance with Polish law and
                by the court with jurisdiction over the Ordering Party’s
                registered office.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Owp;
